import React, { useEffect } from "react";
import { Header } from './Header';
import { useState } from 'react';
import { useLocation } from "react-router-dom";
import { getUser } from "./service/AuthService";
import AccessInformation from "./service/AccessInformation";
import axios from "axios";
import "./WorkHoursStyle.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { IoIosCloseCircleOutline } from "react-icons/io";
import { GoIssueClosed } from "react-icons/go";
import dayjs from "dayjs";
import SearchBarSimple from "./SearchBarSimple";

import CustomDatePicker from "./service/CustomDatePicker";

const { displayTableResultsUrl } = AccessInformation

const ShiftHistory = ({ inputUserInfo }) => {
    const user = getUser();
    const userEmail = user.email;
    const name = user.name;
    const [addressList, setAddressList] = useState([]);
    const [address, setAddress] = useState('');
    const [date, setDate] = useState('');

    const [data, setData] = useState([]);
    const location = useLocation();
    const navigatedUserInfo = location.state?.inputUserInfo || null;

    //use this to call CustomDatePicker
    const [selectedDate, setSelectedDate] = useState(null);

      // State to hold the selected value
  const [selectedOption, setSelectedOption] = useState("date-option");

  // Handle change when a new option is selected
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

    const tableName = 'shift-tracking';
    const [inputValues, setInputValues] = useState('');

    useEffect(() => {
        const filters = {
            email: inputUserInfo ? inputUserInfo.email : (navigatedUserInfo ? navigatedUserInfo.email : userEmail)
        }
        if (selectedOption === "date-option") {
            filters.date = selectedDate
        } else if (selectedOption === "address-option") {
            filters.address = address;
        }
        const requestBody = {
            tableName: tableName,
            filters: filters
        }
        setInputValues(userEmail);
        console.log("selectedDate official: " + selectedDate);
        axios.post(displayTableResultsUrl, requestBody).then(response => { 

            let columnsToRemove;
            if (selectedOption === "date-option") {
              columnsToRemove = ['id', 'email', 'name', 'date'];
            } else if (selectedOption === "address-option") {
              columnsToRemove = ['id', 'email', 'name'];
            }
    
            // Step 2: Filter columns based on selectedOption
            const filteredData = response.data.map(item => {
              // Dynamically exclude columns
              const filteredItem = Object.keys(item).reduce((acc, key) => {
                if (!columnsToRemove.includes(key)) {
                  acc[key] = item[key]; // Keep the property if it's not in the columnsToRemove array
                }
                return acc;
              }, {});
              return filteredItem;
            });

            const uniqueAddresses = new Set(response.data.map(item => item.address));
            if (uniqueAddresses.size > 1) {
                const addressArray = Array.from(uniqueAddresses).map((address, index) => ({
                    text: address,
                    node: `address-${index + 1}`  // Create a unique node based on the index
                  }));
                  
                setAddressList(addressArray);
                console.log("addressArray: ", addressArray);

            }

            console.log("uniqueAddresses: ", uniqueAddresses);
            setData(filteredData);
        }).catch(error => {
            console.error(error);
        });

    }, [selectedOption, selectedDate, address, inputUserInfo]);



    console.log(inputValues)
        // Extract all unique keys from the data objects
    const allKeys = Array.from(
        new Set(data?.flatMap((item) => Object.keys(item)))
    );

    const handleResultClick = (node, text, index) => {
        setAddress(text);
    }
    
    return (
        <div className="work-hours-root">
            {/* <div className="work-hours-root"> */}
            {window.innerWidth < 700 && <Header title="Shift History" />}
              <div className="staff-overview">
                <h2>{inputUserInfo ? inputUserInfo.name : (navigatedUserInfo ? navigatedUserInfo.name : name)}</h2>
                <div className="filter-options">
                  <p>Filtered by: </p>
                  <select value={selectedOption} onChange={handleOptionChange}>
                    <option value="date-option">Date</option>
                    <option value="address-option">Address</option>
                  </select>
                </div>
              </div>


            {selectedOption === "date-option" ? (
              <div
              className="work-hours-date-picker-container"
              >
              <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
              />
            </div>
            ) : (
                <div className="work-hours-search-bar-container">
                    <SearchBarSimple
                    data={addressList}
                    handleResultClick={handleResultClick}
                    resultStyle={{
                      position: 'relative',
                      top: '0'
                    }}
                    searchIconStyle={{
                      display: 'none'
                    }}
                    title="Search by address"
                    />
                    {address && 
                      <p
                      style={{
                        margin: '0',
                        fontWeight: '500',
                        position: 'relative',
                        top: '0px'
                        }}
                        >{address}</p>}
                </div>
            )
            }
                <DataTable
                  value={data}
                  className="work-hours-table"
                  scrollable scrollHeight={window.innerWidth > 700 ? '35em' : '50em'} >
                  {allKeys.map((key) => (
                    <Column
                      key={key}
                      field={key}
                      header={key.toUpperCase()}
                      style={{margin: "0", padding: "5px"}}
                      sortable
                      body={(rowData) =>
                        rowData[key] && typeof rowData[key] === 'object' ? (
                          <ul>
                            {Object.entries(rowData[key]).map(([nestedKey, nestedValue]) => (
                              <li key={nestedKey}>
                                {nestedKey}: <strong>{
                                                nestedValue === true ?
                                                <GoIssueClosed style={{color: "green", fontSize: '14px'}} /> :
                                                <IoIosCloseCircleOutline style={{color: "red", fontSize: '14px'}}/>
                                                }
                                                </strong>
                              </li>
                            ))}
                          </ul>
                        ) : (
                            <>
                            {/* <button id='copy-button' onClick={() => copyText(rowData[key])}><MdContentCopy /></button> */}
                            {rowData[key]}
                            </>
                        )
                      }
                    />
                  ))}
                </DataTable>
            {/* </div> */}
        </div>
    )
}

export default ShiftHistory;