import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './SearchBarStyle.css';

export default function SearchBarSimple(props) {
    const { data, title, handleResultClick, resultStyle, searchIconStyle } = props;
    const [input, setInput] = useState('');
    const [results, setResults] = useState([]);
    const [isStringArray, setIsStringArray] = useState(false);
    const [isResultsVisible, setIsResultsVisible] = useState(true);

    const containerRef = useRef(null);
  
    const fetchData = (value) => {
      setIsResultsVisible(true);
        const filteredResults = data.filter((item) => {
          console.log('item')
          console.log(item)
          return item && item.text.toLowerCase().includes(value.toLowerCase())
        });
        
        console.log("data is:");
        console.log(filteredResults)
      setIsStringArray(true);
      setResults(filteredResults);
    };
  
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (containerRef.current && !containerRef.current.contains(event.target)) {
            // Clicked outside the container (search bar or list), so clear the results
            setResults([]);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
      
      const handleClickInternal = (node, text, index) => {
        handleResultClick(node, text, index);
        setIsResultsVisible(false);
      }
    return (
      <div>
        <div className='search-container' ref={containerRef}>
          <div className='search-content'>
            <input
              id='search-bar'
              placeholder={title}
              value={input}
              onClick={e => fetchData(e.target.value)}
              onChange={(e) => {
                setInput(e.target.value);
                fetchData(e.target.value);
              }}
            />
            <div style={searchIconStyle}>
            <FaSearch id='search-icon' />

            </div>

          </div>
          <section id='list'
            className={isResultsVisible ? 'visible' : 'hidden'}
            style={resultStyle}
            >
              {isStringArray === false ? (
                results.map(({ text, node }, index) => (
                  <li key={index} onClick={() => handleClickInternal(node, text, index)}>
                    {text}
                  </li>
                ))
              ) : (
                  results.map(({ text, node }, index) => (
                      <li key={index} onClick={() => handleClickInternal(node, text, index)}>
                        {text}
                      </li>
                ))
              )}
            </section>
        </div>
      </div>
    );
}