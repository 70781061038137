import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import ShiftHistory from "./ShiftHistory";
import ColumTaskBar from "./ColumnTaskBar";
import "./StaffInfoStyle.css";
import axios from "axios";
import AccessInformation from "./service/AccessInformation";
import { getUser } from "./service/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";

const { displayTableResultsUrl } = AccessInformation;
const tableName = "user-info-table";

const StaffInfo = () => {
    const user = getUser();
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUserInfo, setSelectedUserInfo] = useState();

    const [selectedItem, setSelectedItem] = useState(null);
    // for styling purposes
    const effectRan = useRef(false);
    const navigate = useNavigate();

    const location = useLocation();
    const pathname = location.pathname;
    const isPathActive = (path) => pathname === path;

        useEffect(() => {
            if (effectRan.current === false) {
                console.log('useLocation mounted: ', location.pathname);
                return () => {
                    console.log('useLocation unmounted')
                    effectRan.current = true;
                }
            }
    
        }, [isPathActive])
    
  useEffect(() => {
    const requestBody = {
      tableName: tableName,
    };

    axios
      .post(displayTableResultsUrl, requestBody)
      .then((response) => {
        console.log(response.data);

        // Check if response.data is an array
        if (Array.isArray(response.data.items)) {

            const filteredData = response.data.items.map(item => {
            const { phoneNumber, username, address, id, lonAndLat, password, ...rest } = item;
            return rest;
            });

          console.log("filtered data: ", filteredData);
          setFilteredData(filteredData);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUserEmailChange = (event) => {
    setSelectedUserInfo(event);
    setSelectedItem(event);

    if (window.innerWidth < 700) {
        navigate('/shift-history', { state: { inputUserInfo: event } });
    }
  }
  return (
    <div>
      <Header title="Staff Information" />
      <ColumTaskBar />
      <div className="staff-info-root">
      <div className="staff-list">
        <div className="staff-list-title">
            <h3>Staff list</h3>
        </div>

          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <div
                className="list-item-root"
                key={index}
                style={{
                    backgroundColor: selectedItem === item ? '#484a4b' : 'transparent',
                    color: selectedItem === item ? 'white' : 'black'
                }}
              >
                {/* Render name first */}
                {item.name && <p
                    onClick={() => handleUserEmailChange(item)}
                >{item.name} <br /> {item.email}</p>}
                {/* Render other properties */}
                {Object.entries(item).map(([key, value]) => {
                  if (key !== "name" && key !== "email") {
                    return (
                      <p key={key}>
                        {key}: {value}
                      </p>
                    );
                  }
                  return null;
                })}
              </div>
            ))
          ) : (
            <p>No staff information available.</p>
          )}
        </div>
        <div className="work-hours-tag-container">
            <ShiftHistory inputUserInfo={selectedUserInfo} />
        </div>
      </div>
    </div>
  );
};

export default StaffInfo;