import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import dayjs from 'dayjs';

export default function CustomDatePicker({ selectedDate, setSelectedDate }) {
  // Lift state up to the parent component (parameters)
  const handleChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'));

    if (date) {

      localStorage.setItem('customSelectedDate', dayjs(date).format('YYYY-MM-DD'));
      console.log('Selected Date: ', dayjs(date).format('YYYY-MM-DD'));
    } else {
      localStorage.removeItem('selectedDate'); // Remove from localStorage if no date is selected

    }
  }


  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <div className='custom-date-picker-container'>
            <DatePicker
              label="Tap here to pick a date"
              value={selectedDate ? dayjs(selectedDate) : null}  // Ensure value is always a dayjs object or null
              onChange={handleChange}
              />
          </div>
        </DemoContainer>
      </LocalizationProvider>

    </div>

  );
}