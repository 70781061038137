import React, { useState, useEffect, useRef } from 'react';
import { getUser, resetUserSession } from './service/AuthService';
import LocationFinder from './service/LocationFinderService';
import { useNavigate, useLocation } from 'react-router-dom';
//import './index.css';
import './premiumContentStyle.css'
import { HiOutlineLogout } from 'react-icons/hi';
import { BsCalendarCheck } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { PiQuestion } from "react-icons/pi";
import Logo from './image/cleanntidyLogo.png';
import { RiHome3Line } from "react-icons/ri";
import { BsGraphUpArrow } from "react-icons/bs";
import { PiTableBold } from "react-icons/pi";
import { Header } from './Header';
import AddOrEditJobs from './AddOrEditJobs';


const ColumTaskBar = ({ columnDisplay}) => {
    const effectRan = useRef(false);
    const navigate = useNavigate();
    const user = getUser();
    const name = user !== 'undefined' && user ? user.name : '';
    const email = user !== 'undefined' && user ? user.email : '';

    const location = useLocation();
    const pathname = location.pathname;
    const isPathActive = (path) => pathname === path;

    // console.log('useLocation outside is: ', location.pathname);

    useEffect(() => {
        if (effectRan.current === false) {
            console.log('useLocation mounted: ', location.pathname);
            return () => {
                console.log('useLocation unmounted')
                effectRan.current = true;
            }
        }

    }, [isPathActive])

    const logoutHandler = () => {
        resetUserSession();
        //props.history.push('/login');
        navigate('/login');
        localStorage.clear();
    }
    const profileHandler = () => {
        navigate('/profile');
    }

    const addOrEditJobsHandler = () => {
        navigate('/add-or-edit-jobs')
    }
    const canlendarHandler = () => {
        navigate('/calendarsearching');
    }

    const guidanceHandler = () => {
        window.location.href = 'https://docs.google.com/document/d/12tF2ZcDacsIM58oSXLhqEekfFSTCTY3Lt5UVdvGSNlM/edit?usp=sharing'
    }

    const homeHandler = () => {
        navigate('/premium-content')
    }
    
    const [isActive, setIsActive] = useState(false);

     const toggleMenu = () => {
        setIsActive(!isActive);
     };

    const staffManagementHandler = () => {
        navigate('/staff-management')
    }
    const staffInfoHandler = () => {
        navigate('/staff-info')
    }
    return (
        <div className='column-task-bar-container'>
        <input type='checkbox' id="menu-toggle" className='dropdown-input' />
        <label className={`hamburger ${isActive ? 'active' : ''}` }
                htmlFor="menu-toggle"
                onClick={toggleMenu}
                style={{display: columnDisplay}}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </label>

        

        <section id='dropdown-content'>

            <button className={`item ${isPathActive('/premium-content') ? 'active' : ''}`} style={{'--i': 1}}  onClick={homeHandler}>
                <div className={`circle ${isPathActive('/premium-content') ? 'active' : ''}`}><RiHome3Line /></div>
                Home 
            </button><br />

            {(email === 'thienbao1084@gmail.com' ||
              email === 'cleanntidy.au@outlook.com' ||
              email === 'test2@gmail.com') ? (
                <div>
                    <button className={`item ${isPathActive('/staff-management') ? 'active' : ''}`} style={{'--i': 1}}  onClick={staffManagementHandler}>
                        <div className={`circle ${isPathActive('/staff-management') ? 'active' : ''}`}><BsGraphUpArrow  /></div>
                        Staff Management 
                    </button> <br />
                    <button className={`item ${isPathActive('/staff-info') ? 'active' : ''}`} style={{'--i': 1}}  onClick={staffInfoHandler}>
                        <div className={`circle ${isPathActive('/staff-info') ? 'active' : ''}`}><BsGraphUpArrow  /></div>
                        Staff Information
                    </button>
                 <br />
                </div>
              ) : (
                null
              )}
              
            <button className={`item ${isPathActive('/profile') ? 'active' : ''}`} style={{'--i': 2}} onClick={profileHandler}>
                <div className={`circle ${isPathActive('/profile') ? 'active' : ''}`}><CgProfile /></div>
                <div className='dropdown-content-button-tittle'>Profile </div>
            </button><br />
            <button className={`item ${isPathActive('/calendarsearching') ? 'active' : ''}`} style={{'--i': 3}} onClick={canlendarHandler}>
                <div className={`circle ${isPathActive('/calendarsearching') ? 'active' : ''}`}><BsCalendarCheck /></div>
                Calendar 
            </button> <br />
            <button className='item'style={{'--i': 4}} onClick={guidanceHandler}>
                <div className='circle'><PiQuestion /></div>
                Guidance 
            </button> <br />

            {(email === 'thienbao1084@gmail.com' ||
              email === 'cleanntidy.au@outlook.com' ||
              email === 'test2@gmail.com') ? (
                <div>
                    <button className={`item ${isPathActive('/add-or-edit-jobs') ? 'active' : ''}`} style={{'--i': 1}}  onClick={addOrEditJobsHandler}>
                        <div className={`circle ${isPathActive('/add-or-edit-jobs') ? 'active' : ''}`}><PiTableBold /></div>
                        Add/Edit Jobs 
                    </button>
                 <br />
                </div>
              ) : (
                null
              )}

            <button className='item' style={{'--i': 1}}  onClick={logoutHandler}>
                <div className='circle'><HiOutlineLogout /></div>
                Logout 
            </button>
        </section>
    </div>
    )
}
export default ColumTaskBar;